import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import Gallery from "@solid-ui-blocks/Blog/Block01/Block01";

const LavadoraFrigidaire = props => {
    const { allBlockContent } = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Reparación de Lavadora Frigidaireen Guatemala' description='Realizamos reparaciones de Lavadora Frigidaireen Guatemala con los más altos estándares de calidad. Contamos con técnicos certificados en electrónica.' />
            <Header content={content['header']} />
            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse />
            </Container>
            <Divider space='5' />
            <Content content={content['content-one']} />

            <Divider space='5' />
            <Content content={content['content-one-1']} />

            <Divider space='5' />
            <Content content={content['content-one-2']} />
            <Divider space='5' />
            <Content content={content['content-one-3']} />
            <Divider space='5' />
            <Content content={content['content-one-4']} />
            <Divider space='5' />
            <Content content={content['content-one-5']} />
            <Divider space='5' />
            <Content content={content['content-one-7']} />
            <Divider space='5' />
            <Gallery content={content['gallery']} />
            <Divider space='4' />
            <Footer content={content['footer']} />
        </Layout>
    )
}

export const query = graphql`
  query innerpageLavadoraFrigidaireBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/reparacion/lavadora/frigidaire", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default LavadoraFrigidaire
